import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';
import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size4};
  align-self: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size6};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
  }
`;

export const Link = styled.a`
  color: ${tokens.color.hpBlue6};
  text-decoration: none;

  @media (hover: none) and (pointer: coarse) {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
`;
