import {
  Card,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { ContentContainer, Link } from 'src/styles/styles';
import {
  HpPrivacyStatementHyperLinkClicked,
  YourPrivacyChoicesHyperLinkClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { SubSectionContainer } from './styles';

const PrivacyDetails = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <Card
      title={{
        content: translate(
          'privacy.dataCollection',
          'Data collection'
        ) as string
      }}
      data-testid="privacy-details"
    >
      <ContentContainer>
        <p className="caption">
          {`${translate(
            'privacy.necessaryData',
            'HP collects necessary data from your device and account to deliver basic services. Get to know what personal data HP can use to influence your experience.'
          )}`}
        </p>
        <SubSectionContainer>
          <h3 className="body-large">
            {`${translate('privacy.details', 'Privacy details')}`}
          </h3>
          <Link
            data-testid="HpPrivacyStatementLink"
            className="caption"
            href={URLS.HpPrivacyStatement}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => publishEvent(HpPrivacyStatementHyperLinkClicked)}
          >
            {`${translate('privacy.statement', 'HP Privacy Statement')}`}
          </Link>
          <Link
            data-testid="YourPrivacyChoicesLink"
            className="caption"
            href={URLS.YourPrivacyChoices}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => publishEvent(YourPrivacyChoicesHyperLinkClicked)}
          >
            {`${translate('privacy.choices', 'Your privacy choices')}`}
          </Link>
        </SubSectionContainer>
      </ContentContainer>
    </Card>
  );
};

export default PrivacyDetails;
